<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Générateur de liens
				<small style="font-size: .7rem"><a target="_blank" rel="noopener norefferer" href="https://fr.wikipedia.org/wiki/Code_QR">Qu'est-ce qu'un QR-code ?</a></small></h1>
			<p class="text-muted">Utilisez cette page pour générer un QR-Code avec ou sans campagne.<br>
				Vous pouvez sauvergarder l'image en faisant clic droit <b>"enregistrer l'image"</b>.</p>
		</div>

		<div class="p-col-12">
			<div class="card">

				<div v-if="urlQrCode !== undefined" class="p-d-flex p-jc-center p-mt-4">
					<div style="position: relative; min-width: 300px;" class="p-fluid p-qrcode">
						<div class="p-fluid p-mb-6" style="width: 100%">
							<CampaignSelector :value="eventGuid" @input="eventGuid = $event"></CampaignSelector>
							<small class="text-muted">Attention un QR-code avec une campagne est plus complexe et doit donc être
							affiché plus gros qu'un QR-code plus simple</small>
						</div>
						<div class="p-field">
							<InputText v-model="urlQrCodeComputed" id="link" readonly type="text" />
						</div>
						<div class="p-field p-pt-2 p-pb-2">
							<a rel="noopener nofollow" target="_blank"
									:href="urlQrCodeComputed" class="p-button b-button p-m-2">Voir mon profil !</a>
						</div>
						<QrcodeVue :size="size" level="H" :value="urlQrCodeComputed"></QrcodeVue>
						<div class="p-d-flex p-jc-center">
							<small><a @click.prevent="toggleSize" href="#">version large pour impression</a></small>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import userMixin from "../mixins/userMixin";
	/**
	 * @see https://www.npmjs.com/package/qrcode.vue
	 */
	import QrcodeVue from 'qrcode.vue'

	import CampaignSelector from '../components/app/crm/CampaignSelector.vue';

export default {
	data(){
		return {
			urlQrCode: undefined,
			maxSize: 2000,
			minSize: 800,
			size: 800,
			eventGuid: undefined,
		}
	},
	mounted(){
		this.waitUser();
	},
	methods: {
		toggleSize(){
			if(this.size === this.minSize){
				this.size = this.maxSize;
			} else {
				this.size = this.minSize;
			}
		},
		waitUser(){
			const vm = this;
			if(vm.userData === undefined){
				setTimeout(() => {
					vm.waitUser();
				}, 100);
				return;
			}
			vm.urlQrCode = window.appData.env.profileBaseUrl.replace('{id}', vm.userData.id).replace('{key}', vm.userData.guid.substring(0,10));
		},
	},
	computed: {
		...mapState({
			userData: 'user',
		}),
		urlQrCodeComputed(){
			if(this.eventGuid !== undefined){
				return this.urlQrCode + '?utm_source=' + this.eventGuid;
			}
			return this.urlQrCode;
		}
	},
	mixins: [userMixin],
	components: {
		QrcodeVue,
		CampaignSelector,
	}
}
</script>

<style lang="scss">
	.p-qrcode {
		canvas {
			height: auto!important;
			max-width: 90%!important;
			display: block;
			margin: auto;
		}
	}
</style>
